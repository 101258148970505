import { darken, ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#13347D",
    paper: "#FAFAFA",
  },
  primary: {
    main: "#0066F5",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#E2091B",
  },
  error: {
    main: darken("#D91C28", 0.18),
  },
  info: {
    main: "#6E6E73",
  },
  warning: {
    main: "#6E6E73",
  },
  success: {
    main: "#28B67A",
  },
  text: {
    primary: "#333",
    secondary: "#13347D",
  },
};

export default palette;
