import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PageLayout } from "@APP/components";

const useStyles = makeStyles((theme) => ({
  underline: {
    textDecoration: "underline",
  },
  list: {
    paddingLeft: 30,
  },
  subList: {
    paddingLeft: 40,
  },
}));

const PrivacyNoticeScreen = () => {
  const styles = useStyles();

  return (
    <PageLayout>
      <Box maxWidth={980} alignSelf="center" id="privacyPolicyBox">
        <Typography variant="h5" align="center" className={styles.underline}>
          Privacy Notice
        </Typography>
        <br />
        <Typography variant="body2">
          This Privacy Notice tells you how your personal data is used and protected when you access
          the InvoiceIt Mobile App and Web Services (together “InvoiceIt Services”) and tells you
          about your privacy rights and how the law protects you.
        </Typography>
        <br />
        <Typography variant="body2">
          In this privacy notice when we say <b>“BankiFi”</b> or <b>“we”</b> or <b>“us”</b> or{" "}
          <b>“our”</b> we mean BankiFi Technology Limited. Our registered company number is
          10696778. Our registered office is Centurion House, 129 Deansgate, Manchester, M3 3WR and
          we can be contacted at{" "}
          <u>
            <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>
          </u>
          . BankiFi is the data controller responsible for your personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          When we say <b>“you”</b> or <b>“your”</b>, we mean the person whose personal data we
          process as a result of that person or another person’s use of the InvoiceIt Services.
        </Typography>
        <br />
        <Typography variant="body2" className={styles.list}>
          <ol>
            <li>KEY INFORMATION AND WHO WE ARE</li>
            <li>THE PERSONAL DATA WE COLLECT</li>
            <li>HOW WE COLLECT YOUR PERSONAL DATA</li>
            <li>HOW WE USE YOUR PERSONAL DATA</li>
            <li>PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA</li>
            <li>HOW WE SHARE YOUR PERSONAL DATA</li>
            <li>INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</li>
            <li>DATA SECURITY</li>
            <li>KEEPING YOUR PERSONAL DATA</li>
            <li>YOUR LEGAL RIGHTS</li>
          </ol>
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>1. KEY INFORMATION</b>
        </Typography>
        <br />
        <Typography variant="body2">
          Other privacy documents: It is important that you read this privacy notice together with
          any other privacy notice or fair processing policy we provide to you on specific occasions
          when we collect or process personal data about you so that you are aware of how and why we
          use your personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          Contacting our data protection representative: We have appointed a data protection
          representative who is responsible for overseeing questions about this privacy notice. If
          you have any questions about this privacy notice, including any requests to exercise your
          legal rights, please contact the data protection representative using the details set out
          below:
        </Typography>
        <br />
        <Typography variant="body2">
          Email address:{" "}
          <u>
            <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>
          </u>
          <br />
          Postal address: BankiFi Technology Limited, Beehive Lofts, Jersey Street, Manchester, M4
          6JG
        </Typography>
        <br />
        <Typography variant="body2">
          Contacting the UK data protection regulator: We hope you will let us have an opportunity
          to deal with any concerns about our use of your personal data. However, you have the right
          to make a complaint at any time to the Information Commissioner’s Office – the UK
          regulator for data protection issues{" "}
          <u>
            <a href="https://www.ico.org.uk">(www.ico.org.uk)</a>
          </u>
          .
        </Typography>
        <br />
        <Typography variant="body2">
          Updating our privacy notice: We keep our privacy notice under regular review. This version
          was last updated on 26.09.2023. You can obtain older versions by contacting us.
        </Typography>
        <br />
        <Typography variant="body2">
          Links to other services: The InvoiceIt Services may include links to third-party websites,
          plug-ins and applications. Clicking on those links or enabling those connections may allow
          third parties to collect or share personal data about you. We do not control these
          third-party services and are not responsible for their privacy notices and arrangements.
          When you leave the InvoiceIt Services, we encourage you to read the privacy notice of
          every service you visit.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>2. THE PERSONAL DATA WE COLLECT</b>
        </Typography>
        <br />
        <Typography variant="body2">
          Personal data, or personal information, means any information about an individual that can
          be used to identify that person. It does not include data where the identity has been
          removed (anonymous data).
        </Typography>
        <br />
        <Typography variant="body2">
          We may collect, use, store and transfer different kinds of personal data about you or any
          person whose personal data you provide to us, and which we have grouped together as
          follows:
          <br />
          <ul className={styles.list}>
            <li>
              <b>Identity Data</b> includes first name, last name, username or similar identifier,
              title, bank account number and bank account holder name.
            </li>
            <li>
              <b>Contact Data</b> includes business name and contact details, billing address, email
              address and telephone numbers.
            </li>
            <li>
              <b>Transaction Data</b> includes details about payments to and from you and other
              transaction details from your use of InvoiceIt Services.
            </li>
            <li>
              <b>Technical Data</b> includes internet protocol (IP) address, your login data,
              browser type and version, time zone setting and location, browser plug-in types and
              versions, operating system and platform, and other technology on the devices you use
              to access the InvoiceIt Services.
            </li>
            <li>
              <b>Usage Data</b> includes information about how you use InvoiceIt Services.
            </li>
            <li>
              <b>Marketing and Communications Data</b> includes your preferences in receiving
              marketing from us and our third parties and your communication preferences.
            </li>
          </ul>
        </Typography>
        <br />
        <Typography variant="body2">
          We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic
          data for any purpose. Aggregated Data could be derived from your personal data but is not
          personal data in law as it will not directly or indirectly reveal your identity. For
          example, we may aggregate your Usage Data to calculate the percentage of users accessing a
          specific part of the InvoiceIt Services. However, if we combine or connect Aggregated Data
          with your personal data so that it can directly or indirectly identify you, we treat the
          combined data which will be used in accordance with this privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          We do not collect any Special Categories of Personal Data about you (this includes details
          about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
          orientation, political opinions, trade union membership, information about your health,
          and genetic and biometric data). Nor do we collect any information about criminal
          convictions and offences.
        </Typography>
        <br />
        <Typography variant="body2">
          The InvoiceIt Services are not intended for children, and we do not knowingly collect
          personal data relating to children.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>3. HOW WE COLLECT YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We collect your personal data in the following ways:
          <ul className={styles.list}>
            <li>
              <b>Direct engagement with us:</b> This is where you or another user give us your
              personal data by filling forms on the InvoiceIt Services or by corresponding with us
              by post, phone, email or otherwise. This includes personal data you provide when you:
              <ul className={styles.subList}>
                <li>Apply for our products or services or subsequently use them.</li>
                <li>Create an account on the InvoiceIt Services.</li>
                <li>Subscribe to any InvoiceIt Services or publications.</li>
                <li>Request marketing to be sent to you.</li>
                <li>Enter a competition, promotion or survey; or</li>
                <li>Give us feedback or contact us.</li>
              </ul>
            </li>
            <li>
              <b>Automated technologies:</b> When you interact with the InvoiceIt Services, we will
              automatically collect Technical Data about your equipment, browsing actions and
              patterns. We collect this personal data by using server logs and other similar
              technologies.
            </li>
            <li>
              <b>Information obtained from third parties or publicly available sources:</b> We
              receive personal data about you from various third parties and public sources as set
              out below:
              <ul className={styles.subList}>
                <li>
                  <b>Technical Data</b> from analytics providers [such as Google based outside of
                  the UK]
                </li>
                <li>
                  <b>Identity</b> and <b>Contact Data</b> from publicly available sources such as
                  Companies House and the Electoral Register based inside the UK.
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>4. HOW WE USE YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We only use your personal data when the law allows us to. Most commonly, we use your
          personal data in the following circumstances:
          <ul className={styles.list}>
            <li>
              Where we need to use it to perform a contract we about to enter or have entered with
              you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and
              your interests and fundamental rights do not override those interests.
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
        </Typography>
        <br />
        <Typography variant="body2">
          In most circumstances the legal basis we rely on to process your personal data is that it
          is necessary for our legitimate interests (or those of a third party). Where that is the
          case, the relevant legitimate interests are:
          <ul className={styles.list}>
            <li>Our legitimate interests in complying with our contractual obligations to you.</li>
            <li>
              Our legitimate interests in complying with our statutory and regulatory obligations,
              and the requirements of our regulators.
            </li>
            <li>Our legitimate interests in keeping our records updated.</li>
            <li>
              Our legitimate interests in assessing how customers use the InvoiceIt Services, and
              our products/services available through them, and improving marketing and
              products/services offered to our customers.
            </li>
            <li>
              Our legitimate interests in running our business, administering IT services used
              within it, managing network security, and preventing fraud.
            </li>
          </ul>
        </Typography>
        <Typography variant="body2">
          We do not rely on consent as a legal basis for processing your personal data other than
          where it is to send third party direct marketing communications to you by email or text
          message. You have the right to withdraw consent to marketing at any time by contacting us.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>5. PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          The following purposes are those for which we use your personal data. We may use the same
          piece of personal data for more than one purpose.
          <ul className={styles.list}>
            <li>To register you as a new customer.</li>
            <li>
              To process and deliver your requests (and those of others you have a transactional
              relationship with) for our products and/or services through the InvoiceIt Services.
            </li>
            <li>
              To manage our relationship with you, including by telling you about changes to our
              terms or privacy policy, or asking you to leave a review or take a survey.
            </li>
            <li>To enable you to take part in a prize draw or a competition.</li>
            <li>
              To administer and protect our business and the InvoiceIt Services (including
              troubleshooting, data analysis, testing, system maintenance, support, reporting and
              hosting of data).
            </li>
            <li>
              To deliver relevant content and advertisements to you and to measure or understand the
              effectiveness of the advertising we serve to you.
            </li>
            <li>
              To use data analytics to improve the InvoiceIt Services, and/or marketing, customer
              relationships, or experiences.
            </li>
            <li>
              To make recommendations about goods or services that may be of interest to you (we
              will get your express opt-in consent before we share your personal data with any third
              party for marketing purposes).
            </li>
          </ul>
        </Typography>
        <br />
        <Typography variant="body2">
          You can ask us or third parties to stop sending you marketing messages at any time by
          following the opt-out links on any marketing message sent to you or by contacting us.
        </Typography>
        <br />
        <Typography variant="body2">
          We will only use your personal data for the purposes for which we collected it, unless we
          reasonably consider that we need to use it for another reason and that reason is
          compatible with the original purpose.
        </Typography>
        <br />
        <Typography variant="body2">
          If we need to use your personal data for an unrelated purpose, we will notiy you and we
          will explain the legal basis which allows us to do so or seek your consent if a suitable
          legal basis does not apply.
        </Typography>
        <br />
        <Typography variant="body2">
          We may process your personal data without your knowledge or consent where this is required
          or permitted by law.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>6. HOW WE SHARE YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We may share your personal data with the following third parties:
          <ul className={styles.list}>
            <li>
              Internal third parties – other companies in the same group of companies as BankiFi who
              are based in the UK and provide IT and system administration services.
            </li>
            <li>
              External third parties:
              <ul className={styles.subList}>
                <li>
                  Service providers (acting as data processors) who provide IT and system
                  administration services.
                </li>
                <li>
                  Professional advisers including lawyers, bankers, auditors and insurers who
                  provide consultancy, banking, legal, insurance and accounting services.
                </li>
                <li>
                  HM Revenue and Customs, regulators and other authorities who require reporting o
                  processing activities in certain circumstances.
                </li>
                <li>Fraud prevention agencies.</li>
                <li>
                  Third parties to whom we may choose to sell, transfer or merge parts of our
                  business or our assets.
                </li>
                <li>
                  Metro Bank Plc for the purpose of improving the quality and access to products and
                  services provided.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          We require all third parties to respect the security of your personal data and to treat it
          in accordanc with the law. We do not allow third-party service providers to use your
          personal data for their own purposes and only permit them to process your personal data
          for specified purposes and in accordance with our instructions.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>7. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We do not transfer your personal data outside of the UK.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>8. DATA SECURITY</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We put in place appropriate security measures to minimise the risk of your personal data
          from being accidentally lost, used or accessed in an unauthorised way, altered or
          disclosed. We limit access to your personal data to those personnel who have a business
          need to know. They only process your personal data on our instructions and are subject to
          a duty of confidentiality. We have put in place procedures to deal with any suspected
          personal data breach and will notify you and any applicable regulator of a breach where we
          are legally required to do so.
        </Typography>
        <br />
        <Typography variant="body2">
          We are ISO27001 accredited, which means that we adhere to proper policies and procedures
          in protecting all information we hold, including personal data.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>9. KEEPING YOUR PERSONAL DATA</b>
        </Typography>
        <br />
        <Typography variant="body2">
          We will only keep your personal data for as long as reasonably necessary to fulfil the
          purposes we collected it for, including to satisfy any legal, regulatory, tax accounting
          or reporting requirements. We may retain your personal data for a longer period if there
          is a complaint or if we reasonably believe there is a prospect of litigation in respect to
          our relationship with you.
        </Typography>
        <br />
        <Typography variant="body2">
          We consider the amount, nature and sensitivity of the personal data, and the potential
          risk of harm from unauthorised use or disclosure in determining the appropriate retention
          period for personal data.
        </Typography>
        <br />
        <Typography variant="body2">
          Details of retention periods for different aspects of your personal data are available in
          our retention policy which you can request by contacting us.
        </Typography>
        <br />
        <Typography className={styles.underline}>
          <b>10. YOUR LEGAL RIGHTS</b>
        </Typography>
        <br />
        <Typography variant="body2">
          You have the following rights under data protection law in relation to your personal data.
          <ul className={styles.list}>
            <li>
              <b>Request access to your personal data (‘data subject access request’ or ‘DSAR’):</b>{" "}
              This allows you to receive a copy of the personal data we hold about you and to
              confirm information showing that we are lawfully processing it.
            </li>
            <li>
              <b>Request correction of your personal data:</b> This allows you to have us correct or
              update any incomplete or inaccurate data we hold about you (subject to us verifying
              the accuracy of the new data).
            </li>
            <li>
              <b>Request erasure of your personal data:</b> This allows you to ask us to delete or
              remove personal data if we have no good reason for continuing to process it, where you
              have successfully exercised your right to object to processing, where we may have
              processed your information unlawfully, or where we have to erase your personal data to
              comply with local law. We may not always be able to comply with your request for
              specific legal reasons which we will notify to you, if applicable, at the time. If you
              wish to make this type of request please contact us at invoiceit.erasure@bankifi.com.
            </li>
            <li>
              <b>Object to processing of your personal data:</b> This allows you to object to us
              processing your personal data where we are relaying on a legitimate interest (or those
              of a third party) and there is something in your circumstances which makes you want to
              object to processing on this ground as it impacts in your fundamental rights and
              freedoms. This also allows you to object where we are processing your personal data
              for direct marketing purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which override your rights
              and freedoms.
            </li>
            <li>
              <b>Request restriction of processing your personal data:</b> This allows you to ask us
              to suspend the processing of your personal data where: (i) you want us to establish
              the data’s accuracy; (ii) our use of the data is unlawful but you do not want us to
              erase it; (iii) you need us to hold the data even if we no longer require it as you
              need to establish, exercise or defend legal claims; (iv) you have objected to our use
              of your data but we need to verify whether we have overriding legitimate grounds to
              use it.
            </li>
            <li>
              <b>Request transfer of your personal data:</b> This allows us to send you, or a third
              party you nominate, your personal data in a structured, commonly used,
              machine-readable format. This only applies to automated information which you
              initially provided consent for us to use or where we used the information to perform a
              contract with you.
            </li>
            <li>
              <b>Right to withdraw consent:</b> This allows you to ask us to stop processing your
              personal data where we are relying on your consent to process it. This will not affect
              the lawfulness of any processing carried out before you withdraw your consent. If you
              withdraw your consent, we may not be able to provide certain products or services to
              you. We will advise you if this is the case at the time you withdraw your consent.
            </li>
          </ul>
          <br />
          If you wish to exercise any of the rights set out above, please contact us.
        </Typography>
        <br />
        <Typography variant="body2">
          You do not have to pay a fee to exercise your rights over your personal data. However, if
          your request is clearly unfounded, repetitive or excessive we are allowed to refuse to
          comply with it. If that is the case, we may be prepared to comply with your request if you
          pay a reasonable fee.
        </Typography>
        <br />
        <Typography variant="body2">
          We may need specific information from you to help us confirm your identify before
          complying with any request to exercise your rights over your personal data. This is to
          ensure that we do not disclose that personal data to any person who does not have the
          right to receive it. We may also ask you for further information about your request to
          speed up our response.
        </Typography>
        <Typography variant="body2">
          We try to respond to all legitimate requests within one month. If we are allowed to take
          longer (e.g., because your request is particularly complex, or you have made a number of
          requests) we will notify you and keep you updated of the timeframe for our response.
        </Typography>
        <br />
        <Typography variant="body2">Version 1.0</Typography>
        <Typography variant="body2">September 2023</Typography>
      </Box>
    </PageLayout>
  );
};

export default PrivacyNoticeScreen;
