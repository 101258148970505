import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PageLayout } from "@APP/components";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.body2.fontFamily,
    "& li": {
      ...theme.typography.body2,
    },
    "& ol": {
      paddingLeft: 20,
    },
    "& ol li": {
      paddingLeft: 20,
    },
    "& ul ul": {
      paddingLeft: 40,
    },
    "& p strong": {
      textDecoration: "underline",
    },
  },
}));

const TermsAndConditionsScreen = () => {
  const styles = useStyles();

  return (
    <PageLayout>
      <Box className={styles.root} maxWidth={980} alignSelf="center" id="termsAndConditionsBox">
        <Typography variant="h5">
          The BankiFi InvoiceIt Mobile App and Web services – Key Terms
        </Typography>
        <br />
        <ol type="A">
          <li>
            These <b>BankiFi InvoiceIt</b> Terms and Conditions (the &ldquo;Terms&rdquo;) set out
            the terms that apply to your access to the <b>InvoiceIt</b> Mobile App and Web Services
            and use of Our Services, including websites and <b>InvoiceIt</b> platform operated by us
            which support the <b>InvoiceIt</b> service (together &ldquo;Our Service&rdquo; or
            &ldquo;Our Services&rdquo;).&nbsp;
          </li>
          <br />
        </ol>
        <Typography variant="subtitle2">
          <b>InvoiceIt</b> is a brand style belonging to Metro Bank PLC. Metro Bank PLC and BankiFi
          Technology Limited have formed a partnership to provide services to businesses. Under this
          partnership, Metro Bank PLC have authorised BankiFi Technology Limited to use the{" "}
          <b>InvoiceIt</b> brand style. By entering these Terms of Use you are entering into an
          agreement exclusively with BankiFi Technology Limited. BankiFi Technology Limited may
          share some of your data with Metro Bank PLC, with the purpose of improving the quality of
          our services and giving you access to products and services provided by the Bank.
        </Typography>
        <br />
        <ol type="A" start={2}>
          <li>
            You should read these Terms once you have registered for Our Service, they will form an
            agreement between you and us which you will be bound by. You can access additional
            copies of these Terms by contacting us at&nbsp;
            <u>
              <a href="mailto: invoiceit.support@bankifi.com ">invoiceit.support@bankifi.com</a>
            </u>
            . Our Services are intended to be used by business customers.&nbsp;
          </li>
          <br />
        </ol>
        <ol type="A" start={3}>
          <li>
            We have used certain words and phrases in the Terms which have the meanings ascribed to
            them below. When we say:
          </li>
          <br />
        </ol>
        <ul>
          <ul>
            <li>
              “BankiFi “or “we” or “us” or “our” we mean BankiFi Technology Limited. Our registered
              office is Centurion House, 129 Deansgate, Manchester, M3 3WR and we can be contacted
              at{" "}
              <u>
                <a href="mailto:info@bankifi.com">info@bankifi.com</a>
              </u>
              .
            </li>
            <li>
              “you” or “your” we mean the person or business who has registered an account to use
              the <b>InvoiceIt</b> services.
            </li>
            <li>
              “our partner” we mean Metro Bank PLC, PayPoint Payment Services Ltd or Moneyhub
              Financial Technology Ltd.
            </li>
            <li>“payer” we mean the person who you have asked to make payment to you.</li>
            <li>“payee” we mean the person who has requested a payment be made to them.</li>
            <li>
              “Requests to Pay” or “RtP” we mean the messages that are sent between a Payee and a
              Payer in Our Services.
            </li>
            <li>
              “Confirmation of Payee” or “CoP” we mean the service provided by PayPoint Payment
              Services Ltd which we will use to verify the Account Holder Name matches the bank
              account you wish to be paid into.
            </li>
          </ul>
        </ul>
        <br />
        <ol type="A" start={4}>
          <li>
            Our Services are made up of a number of elements which may (from time to time) include
            websites, our <b>InvoiceIt</b> web application, <b>InvoiceIt</b> mobile application and
            our Payer Web Application. The elements that you have access to will depend upon the
            channel you use to log on to Our Service. These Terms provide an overarching framework
            of contractual terms that apply to your use of Our Services. Our Services enable you/us
            to:
          </li>
          <br />
          <ul>
            <li>
              Request a payment from a payer (a Request to Pay or “RtP”). These payers are not
              required to register for Our Service and therefore are not bound by these Terms.
            </li>
          </ul>
          <br />
          <li>
            Confirmation of Payee (“CoP”) services are provided by PayPoint Payment Services Ltd
            with whom we partner. When you register for the <b>InvoiceIt</b> service we will use CoP
            to verify the Account Holder name matches the bank account you provide for payments to
            be made into.
          </li>
          <br />
        </ol>
        <Typography variant="subtitle2">
          To make a payment the Payer accesses their own bank’s mobile or web banking services via
          our partner <b>Moneyhub Financial Technology Ltd</b>. The Payer is presented with a
          prefilled payment page. The Payer may not be registered with Our Service and therefore not
          bound by the Terms of Our Service. If the Payer does not wish to accept the RtP they can
          decline the request and settle the payment through other means. Full conditions of use for
          the Moneyhub payment services are available here:{" "}
          <u>
            <a href="https://content.moneyhub.co.uk/terms/api-terms-v1.pdf">
              https://content.moneyhub.co.uk/terms/api-terms-v1.pdf
            </a>
          </u>
          .
        </Typography>
        <br />
        <ol type="A" start={5}>
          <li>
            <b>Disputes</b> If there is a dispute with a payment this needs to be settled directly
            between the Payee and the Payer, not through Our Service. We take no responsibility for
            the validity of the payment request as this is initiated and controlled entirely by the
            Payee, and a Payer can decline to make payment at any time.
          </li>
        </ol>
        <br />
        <br />
        <Typography variant="subtitle1">
          <b>
            BY ACCEPTING THESE TERMS, THE USER IS CONFIRMING THAT IT HAS THE AUTHORITY TO ENTER INTO
            AND BIND THE ACCOUNT HOLDER TO THESE TERMS.
          </b>
        </Typography>
        <br />
        <br />
        <Typography variant="h5">The Terms</Typography>
        <br />
        <Typography variant="body2">
          <strong>1. Registering to use Our Service (as a Payee)</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          1.1 In order to issue RtPs to your customers, you must register for Our Services through
          the Web based registration service. Whilst registering you will be asked to confirm that
          you agree with these Terms of Use and that you consent to us checking your name and bank
          details provided using the CoP service. When you register, we will ask for information so
          that we can validate who you are in relation to your business. Some of this information is
          needed so that we can communicate with you and provide you with Our Service.
        </Typography>
        <br />
        <Typography variant="body2">
          1.2 We respect your personal information and understand its importance to you and
          therefore abide by all the relevant data protection legislation. We only use your personal
          data in accordance with our privacy notice. For information on how Bankifi processes your
          personal data, see our Privacy Notice at{" "}
          <u>
            <a href="https://app.invoiceit.bankifi.com/privacy-notice">
              https://app.invoiceit.bankifi.com/privacy-notice
            </a>
          </u>
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>2. Sending RtPs</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          2.1 Once you have registered and we have successfully verified your bank account you can
          start to send RtPs to your Customers (Payers). We may limit the amount and/or volume of
          payments you can request at any time if we reasonably consider there is a risk of fraud.
          When an RtP is sent it remains open (“Open Requests”) until it is either paid, or declined
          by the Payer, or it is cancelled by you. You can cancel RtPs that have not been paid
          (“Cancelled Requests”). When a payer pays an RtP we will show that is has been paid (“Paid
          Requests”). We show you the history of Open Requests, Paid Requests and Cancelled requests
          in the mobile app and web app.
        </Typography>
        <br />
        <Typography variant="body2">
          2.2 We will let you know if a Payer rejects an RtP. We will also allow the Payer to block
          any future request from you, for example if the Payer decides you are sending too many
          reminders to pay an Open Request.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>3. Linking Accounting Software</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          3.1 We provide you with the ability to link your accounting software (if you use Sage,
          Xero or Quickbooks) during registration or at any time through Our Service. You can do so
          by logging into your accounting software through Our Service and then by following the
          in-app instructions. We never need to know any of your account credentials for your
          accounting software.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>4. Leaving Our Service</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          4.1 You can leave Our Service at any time in a number of ways. You can deactivate your
          account via a feature in the Service or you can email your request to{" "}
          <u>
            <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>
          </u>
          . You will not be able to send any new RtP or resend any Open Requests. RtPs which are
          open can still be paid, and we will continue to mark the items as Paid in any linked
          accounting package. Note that simply deleting the <b>InvoiceIt</b> mobile app without
          using one of the options above will not result in your account being closed.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>5. Communicating with You</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          5.1 We will only communicate with you to operate or improve Our Service. When we introduce
          new features to Our Service, we will let you know.
        </Typography>
        <br />
        <Typography variant="body2">
          5.2 When we communicate, we may contact you using the email address you gave us when you
          registered. We may also send you notifications through Our Service via email or SMS. We
          will communicate with you in the English language.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>6. Using our Service in an Acceptable Way</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          6.1 To help us ensure that Our Service is safe and useful for all users, you agree that
          you will use Our Service only in accordance with these Terms of Use. We may change our
          Terms of Use if we change the services we provide. We will let you know by email each time
          the Terms are changed.
        </Typography>
        <br />
        <Typography variant="body2">
          6.2 If you breach our Terms of Use, we may disable or suspend your account.
        </Typography>
        <br />
        <Typography variant="body2">
          6.3 You will access and use Our Service only for legal, authorised, and acceptable
          purposes. You will not use or help another to use Our Service to:
        </Typography>
        <br />
        <ol type="a">
          <li>
            violate, misappropriate, or infringe the rights of BankiFi, our users, or others,
            including privacy, publicity, intellectual property, or other proprietary rights;
          </li>
          <li>
            instigate or encourage conduct that would be illegal or otherwise inappropriate
            including but not limited to: illegal, obscene, defamatory, threatening, intimidating,
            harassing, sexually explicit, hateful or offensive content;
          </li>
          <li>communicate falsehoods, misrepresentations, or misleading statements;</li>
          <li>impersonate someone; or</li>
          <li>
            send illegal or impermissible communications or send RtPs to people who do not owe you
            money or who would not reasonably expect to receive an RtP from you.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          6.4 You will not, nor help others to, directly or indirectly, whether through automated
          means or otherwise, use, access, distribute, grant a licence or sub-licence (or purport to
          distribute, grant a licence or sub-licence), copy, modify, adapt, create derivative works
          from, or otherwise exploit BankiFi Technology Limited and/or Our Service beyond the usage
          allowed by the Terms.
        </Typography>
        <br />
        <Typography variant="body2">
          6.5 You will not, nor help others to, directly or indirectly, whether through automated
          means or otherwise:
        </Typography>
        <br />
        <ol type="a">
          <li>
            reverse engineer, decompile, or extract code (in whole or in part) from BankiFi
            Technology Limited or Our Service;
          </li>
          <li>
            send, store, or transmit viruses or other harmful computer code through or onto Our
            Service;
          </li>
          <li>gain or attempt to gain unauthorised access to Our Service;</li>
          <li>
            interfere with or disrupt the safety, security, or performance Our Service, our systems
            or platform;
          </li>
          <li>
            create accounts for use in Our Service through unauthorised or automated means or
            circumvent our anti-money laundering or anti-fraud measures or attempt to do so;
          </li>
          <li>
            abuse Our Service (or attempt to do so) by collecting information of or about the system
            or its users in any impermissible or unauthorised manner;
          </li>
          <li>
            sell, resell, grant a licence or sub-licence, distribute or charge or purport to take
            any similar actions for BankiFi when you are not authorised in writing by us to do so;
          </li>
          <li>
            create software, a platform or APIs that function substantially the same as Our Service
            having copied Our Service and offer them for use by anyone else in an unauthorised
            manner.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          6.6 If we reasonably suspect you are committing any fraudulent activity through Our
          Service, we may terminate your account at any time without notice and provide your details
          to the relevant authorities.
        </Typography>
        <br />
        <br />
        <Typography variant="body2">
          <strong>7. Licences and Use of Data</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          7.1 You and we grant each other certain rights so that you can use Our Service. You own
          the information that you send through Our Service. We use this information via the consent
          granted to us as part of Our Service. We have no other rights to use this consent for any
          other purpose. We will not share your data with anyone outside of BankiFi Technology
          Limited or Metro Bank PLC. We will not sell your data to anyone.
        </Typography>
        <br />
        <Typography variant="body2">
          7.2 We and our partners own all copyrights, trademarks, domains, logos, trade dress, trade
          secrets, patents, and other intellectual property rights associated with the{" "}
          <b>InvoiceIt</b> Service. You may not use such copyrights, trademarks, domains, logos,
          trade dress, patents, or other intellectual property rights unless you have our prior
          express written permission and in accordance with our and/or our partners brand purpose,
          guidelines, and values.
        </Typography>
        <br />
        <Typography variant="body2">
          7.3 We grant you a limited, revocable, non-exclusive, non-sublicensable, and
          non-transferable licence to use Our Service, subject to and in accordance with these
          terms. This licence is for the sole purpose of enabling you to use Our Service as we
          describe in these terms. No other licences or rights are granted to you by implication or
          otherwise.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>8. Limitation of Our Liability</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          8.1 Our Service is designed to be available to use as much as possible 24 hours a day, 7
          days a week subject to planned and unplanned outages. We use our reasonable endeavours to
          reduce any outages and to give warning when an outage is planned. We also schedule planned
          outages during the hours when the service has the minimum usage.
        </Typography>
        <br />
        <Typography variant="body2">
          8.2 When we use PayPoint’s CoP services as part of verifying your bank account details we
          and Our Partners rely upon the relevant bank’s connectivity, which is not available 100%
          of the time. If their connection is unavailable for whatever reasons, we, in these
          circumstances cannot be held liable.
        </Typography>
        <br />
        <Typography variant="body2">
          8.3 When we attempt to produce an RtP from an invoice or mark an invoice as paid through
          the link to Accounting Software, we rely upon the relevant Accounting Software
          connectivity, which is not available 100% of the time. If their connection is unavailable
          for whatever reason, we in these circumstances cannot be held liable.
        </Typography>
        <br />
        <Typography variant="body2">
          8.4 Events beyond our control may also affect Our Service (“Force Majeure Events”). If a
          Force Majeure Event occurs, we apologise for any inconvenience it may cause and you can be
          assured that we will take all reasonable actions to get Our Service back up and running
          quickly. We are not liable for the non-provision of Our Service or any losses, howsoever
          arising, as a result of a Force Majeure Event.
        </Typography>
        <br />
        <Typography variant="body2">
          8.5 Our Service is provided on an “as is” and “as available” basis and we do not make any
          warranties, claims or representations with respect to the Our Service including, without
          limitation, quality, performance, non-infringement, merchantability or fitness for use for
          a particular purpose. We do not represent or warrant that availability or use of Our
          Service will be uninterrupted, timely, secure, error-free or virus-free.
        </Typography>
        <br />
        <Typography variant="body2">
          8.6 We do not accept responsibility or liability for a failure of your mobile network, or
          internet provider, or any losses or damage suffered as a result, as this is outside our
          control, as well as keeping all such connecting devices safe and secure and paying any
          mobile, data and or internet connection and service charges.
        </Typography>
        <br />
        <Typography variant="body2">
          8.7 We use reasonable endeavours to ensure that Our Service does not contain or spread any
          viruses or other malicious code. However, we recommend that you regularly check your
          devices for the presence of viruses and other malicious code. We exclude to the fullest
          extent permitted by Web Applicable laws all liability in connection with any damage or
          loss caused by computer viruses or other malicious code originating or contracted from Our
          Service.
        </Typography>
        <br />
        <Typography variant="body2">
          8.8 You should note that the transmission of information via the internet is not
          completely secure, and we do everything we can to keep all the information we store safe
          and secure. Any transmission of information via the internet is at your own risk. We are
          not responsible for circumvention of any privacy settings or security measures contained
          on our platform and we exclude to the fullest extent permitted by law all liability in
          connection with the circumvention of any privacy settings or security measures contained
          on our platform.
        </Typography>
        <br />
        <Typography variant="body2">
          8.9 Where we use the connection to your Accounting Software provider to create RtPs from
          invoices, we do not check and we are not responsible for the accuracy of information
          acquired from your Accounting Software provider. We will relay the exact information we
          are given and we rely on You and your Accounting Software provider to ensure that the
          information provided to us is up to date, true and accurate.
        </Typography>
        <br />
        <Typography variant="body2">
          8.10 We will not be liable in the event Our Service is proven to be faulty unless a claim
          is notified to us in writing. The claim will need to include details we need to identify
          the RtP(s), the Payee and the Payer involved. The claim must be made within 35 days of the
          RtP in question being sent (if a complaint is being made by the Payee) or received (if a
          complaint is being made by a Payer). If you fail to notify us of a problem in accordance
          with this Term, we will not be held liable for that problem. In the case of a valid claim,
          we will follow our complaints policy. We will have no further liability to you in respect
          of that claim. If there is an issue with the payment transaction, then the Moneyhub
          service terms will apply.
        </Typography>
        <br />
        <Typography variant="body2">
          8.11 If something has gone wrong, please contact us by email at&nbsp;
          <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a> so that
          we can do our best to help.
        </Typography>
        <br />
        <Typography variant="body2">
          8.12 If you want to make a complaint about Our Service, please contact us at{" "}
          <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>
          . We will try to get it resolved quickly and address the points you have raised. If your
          complaint relates to Moneyhub Services, details of Moneyhub’s complaints process can be
          found in the Moneyhub conditions of use. If you make a complaint to us, but we believe
          that Moneyhub may be solely or jointly responsible for the matter alleged we may forward
          your complaint to Moneyhub. We will tell you if we do this.
          <br />
          <br />
          We’ll send you a written acknowledgement within five working days and keep you informed of
          our progress until your complaint has been resolved. We’ll do everything we can to sort
          out the problem.
          <br />
          <br />
          If you still remain dissatisfied, you may be entitled to refer your complaint to the
          ombudsman service using the contact details set out below. If you’re not eligible to use
          the ombudsman service, please note we don’t use any alternative dispute resolution
          services.
          <br />
          <br />
          <ul>
            <ul>
              <li>Exchange Tower, London, E14 9SR</li>
              <li>
                <a href="https://www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</a>
              </li>
              <li>
                <a href="mailto:complaint.info@financial–ombudsman.org.uk">
                  complaint.info@financial–ombudsman.org.uk
                </a>
              </li>
            </ul>
          </ul>
        </Typography>
        <br />
        <Typography variant="body2">
          8.13 We, our agents, directors, officers, shareholders, employees and subcontractors of
          BankiFi and our subsidiary will not be liable to you or anyone else, whether in contract,
          tort (including negligence, breach of statutory duty or other tort) or otherwise, for any:
        </Typography>
        <br />
        <ol type="a">
          <li>
            loss of data, sales or business, agreements or contracts, anticipated savings, profits,
            opportunity, goodwill or reputation, or for any business interruption;
          </li>
          <li>loss or corruption of data; or</li>
          <li>
            indirect, special or consequential loss, damage, costs or other claims, howsoever caused
            or arising, including where arising directly or indirectly from any failure or delay in
            performing any obligation under these terms caused by matters beyond our reasonable
            control. Except as expressly stated elsewhere in these terms, all representations,
            warranties, conditions and other terms, whether express or implied (by common law or
            statute) are hereby excluded, except in the case of fraud, death and personal injury, or
            where such exclusion is not permitted by law.
          </li>
        </ol>
        <br />
        <Typography variant="body2">
          8.14 Our aggregate liability to you, arising out of or in connection with providing you
          with Our Service under these Terms, shall be limited to losses that are reasonably
          foreseeable and which arose because of our breach of these terms or negligence (except in
          relation to death, personal injury, or fraudulent misrepresentation which we do not
          limit), and will not exceed the amount you have paid us in the past twelve months.
        </Typography>
        <br />
        <Typography variant="body2">
          8.15 All views of your payments, accounts and RtPs (sent and received) and all the
          capabilities within Our Service are to help you manage your own payments and finances. We
          do not recommend any particular action (or inaction). We are not your advisors. You must
          make your own decisions about your own payments, accounts and finances.
        </Typography>
        <br />
        <Typography variant="body2">
          8.16 Our Service is a secure messaging and communication service allowing Payees and
          Payers to send and receive RtPs and payments simply, swiftly and securely. BankiFi
          Technology Limited is not responsible for collecting or holding money, lending or ensuring
          bills are affordable, paid, actioned or read.
        </Typography>
        <br />
        <Typography variant="body2">
          8.17 We are not liable for any payments made by a Payer through Our Service that are not
          due to be paid. Any dispute between a Payee and a Payer must be settled directly between
          the Payee and Payer and not through Our Service. If a RtP is received by a Payer and the
          Payer is not certain that the payment is due or the Payee is the correct recipient of the
          payment, then the Payer should not make the payment.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>9. Indemnification</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          9.1 You will indemnify us, our subsidiary and our directors and officers (acting
          reasonably) from any and all third-party claims, liabilities, damages, expenses and costs
          (including, but not limited to, reasonable solicitors’ fees) caused by or arising from
          your use of Our Service, your violation of these terms or your infringement of any
          intellectual property or other such right of any third party.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>10. Suspicious or Unlawful Conduct</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          10.1 We may wish to modify, suspend, or block your access to or use of Our Service at any
          time for suspicious or unlawful conduct, including for fraud, or if we reasonably believe
          you breach our terms or create harm, risk, or possible legal exposure for us, our users,
          or others. If we do this, the following clauses will still be in existence between us and
          will survive any termination of our relationship: Licences, Indemnification, Limitation of
          our Liability and Legal and Definitions. If you believe your account has been blocked or
          suspended by mistake, please contact us using{" "}
          <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>11. General</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          11.1 As a user of Our Service you agree to use it in accordance with our terms, which
          include behaving fairly and considerately. We have designed Our Service to be safe and
          secure from a technology perspective. We work to protect the use of Our Service by system
          monitoring for inappropriate behaviour and spamming. We prohibit the misuse of Our
          Service. We will take action as appropriate where we become aware of such activity. We may
          contact relevant law enforcement bodies if we think it is appropriate to do so. In the
          event outside law enforcement bodies become involved, we will share relevant information
          with them when we are required to.
        </Typography>
        <br />
        <Typography variant="body2">
          11.2 In order for Our Service to operate properly, you will need to download and install
          updates when they are available manually or automatically. This helps to keep Our Service
          secure, up-to-date, relevant, and useful.
        </Typography>
        <br />
        <Typography variant="body2">
          11.3 We have designed and built Our Service for the UK and it complies with all laws of
          England and Wales. There may be differences abroad and you are responsible for your use of
          Our Service abroad, and any mobile/internet/data charges that you may incur.
        </Typography>
        <br />
        <Typography variant="body2">
          11.4 Unless we have entered into a separate contract, these Terms make up the entire
          agreement between you and us regarding Our Service. These Terms supersede and extinguish
          all previous agreements, promises, assurances, warranties, representations, and
          understandings between us, whether written or oral, and you confirm you haven’t entered
          into this agreement on the basis of, or relying on, any of these.
        </Typography>
        <br />
        <Typography variant="body2">
          11.5 Our Service is not intended for distribution to or use in any country where such
          distribution or use would break local law or would subject BankiFi Technology or Our
          Service to regulations in another country.
        </Typography>
        <br />
        <Typography variant="body2">
          11.6 Any amendment or waiver to these terms requires our prior written consent.
        </Typography>
        <br />
        <Typography variant="body2">
          11.7 All our rights and obligations in these terms are assignable by us to our subsidiary
          or affiliated companies or in connection with a merger, acquisition, restructuring, or
          sale of assets, or by operation of law or otherwise, and we may transfer your information
          to our subsidiary, affiliates, successor entities, or new owner once we have assured
          ourselves appropriate security and safeguards are in place. In the event of such an
          assignment, these terms will continue to govern your relationship with such third party.
          If you do not agree to these terms, you can leave Our Service.
        </Typography>
        <br />
        <Typography variant="body2">
          11.8 You will not transfer any of your rights or obligations under these terms to anyone
          else without our prior written consent.
        </Typography>
        <br />
        <Typography variant="body2">
          11.9 Nothing in our terms will prevent us from complying with the law.
        </Typography>
        <br />
        <Typography variant="body2">
          11.10 Except where we specifically describe in these terms, our terms do not give any
          rights to third parties.
        </Typography>
        <br />
        <Typography variant="body2">
          11.11 If we do not enforce any of our terms, this does not mean that we have waived them
          and we reserve our rights to take action, in accordance with these terms.
        </Typography>
        <br />
        <Typography variant="body2">
          11.12 If any provision of these terms is found to be unlawful, void, or for any reason
          unenforceable, then we will remove that provision and the rest shall remain in force.
        </Typography>
        <br />
        <Typography variant="body2">
          11.13 Our terms are not intended to limit your legal rights as a consumer or data subject;
          aside from this and the liability exclusions outlined in the Liability clause, we reserve
          all rights not expressly granted by us to you. Your rights as a data subject are set out
          in our privacy notice.
        </Typography>
        <br />
        <Typography variant="body2">
          11.14 These terms of use and any non-contractual rights and obligations arising in
          connection with them shall be governed by and construed in accordance with the laws of
          England and Wales. We and you submit to the exclusive jurisdiction of the courts of
          England and Wales.
        </Typography>
        <br />
        <Typography variant="body2">
          11.15 If you have any thoughts on what we are doing or how we are doing it we would like
          to hear from you: please contact us by email at{" "}
          <a href="mailto:invoiceit.support@bankifi.com">invoiceit.support@bankifi.com</a>. You
          understand that you have no obligation to talk to us and provide feedback or suggestions
          and we may use your feedback or suggestions without any obligation to compensate you for
          them financially or otherwise.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>12. Protecting your user details</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          12.1 You must not disclose your login details for Our Service to anyone. If you know or
          suspect someone else knows your login details, you must change them as soon as possible.
        </Typography>
        <br />
        <Typography variant="body2">
          12.2 We accept no responsibility for any losses or damages resulting from you sharing your
          login details.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>13. Ending your access to Our Services</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          13.1 You can end your access to Our Services at any time by following the processes set
          out in section 4.
        </Typography>
        <br />
        <Typography variant="body2">
          3.2 We can temporarily suspend or permanently cancel your access to Our Services on one
          months’ notice if we decide to withdraw Our Services. We can temporarily suspend or
          permanently cancel your access to Our Services immediately if:
        </Typography>
        <br />
        <ol type="a">
          <li>
            Metro Bank PLC and BankiFi Technology Limited end the partnership to provide Our Service
          </li>
          <li>If Metro Bank PLC close your Metro Bank Business Current Account</li>
          <li>a third party who provides the relevant service ceases to provide it;</li>
          <li>
            we reasonably believe that you have been misusing Our Services (or intend to do so);
          </li>
          <li>you are in material breach of these Terms or</li>
          <li>we are unable to provide Our Services for reasons beyond our control.</li>
        </ol>
        <br />
        <Typography variant="body2">
          13.3 In any of the circumstances above your access to Our Services will cease, and you
          will have no further access to them.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>14. Changes to these terms</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          14.1 From time to time we may amend these terms, including withdrawing or making changes
          to Our Services. We will normally give you 30 calendar days’ notice, unless it is not
          practical to do so, in which case we will tell you as soon as we can after the change
          takes effect.
        </Typography>
        <br />
        <Typography variant="body2">
          14.2 From time to time we may decide to provide additional services. We may change these
          terms to include those additional services without giving prior notice to you. We will,
          however, send you a copy of the updated terms by email at the time the changes are made
          using the email address you provided when you registered for Our Service. Once you have
          been notified of a change, by continuing to use Our Services, you will be considered to
          have accepted the new terms.
        </Typography>
        <br />
        <Typography variant="body2">
          14.3 We reserve the right to introduce fees for Our Service in future. We will give you 30
          calendar days’ notice. You will have the right to leave Our Service without penalty if you
          choose to do so.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>15. Applicable law</strong>
        </Typography>
        <br />
        <Typography variant="body2">
          15.1 These Terms and any dispute or claim arising out of or in connection with them or
          their subject matter or formation (including non-contractual disputes or claims) shall be
          governed by and construed in accordance with the law of England and Wales. We both agree
          that the courts of England and Wales shall have exclusive jurisdiction to settle any
          dispute or claim arising out of or in connection with these Terms or their subject matter
          (including non-contractual disputes or claims).
        </Typography>
        <br />
        <Typography variant="body2">Version 1.0</Typography>
        <Typography variant="body2">September 2023</Typography>
      </Box>
    </PageLayout>
  );
};

export default TermsAndConditionsScreen;
