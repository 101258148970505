import { ThemeOptions, SimplePaletteColorOptions, lighten } from "@mui/material";
import palette from "./palette";

// Due to issues with runtime type checking causing errors when using palette.primary.main / palette.secondary.main from MUI type, had to define again internally.
const palettePrimary = palette.primary as SimplePaletteColorOptions;

const components: ThemeOptions["components"] = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&:focus-visible, &:focus-visible:hover": {
          outline: `2px solid ${palettePrimary.main}`,
          outlineOffset: "-2px",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedSecondary: {
        border: `1px solid ${(palette.secondary as SimplePaletteColorOptions).main}`,
        color: (palette.secondary as SimplePaletteColorOptions).main,
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: lighten((palette.secondary as SimplePaletteColorOptions).main, 0.95),
          boxShadow: "none",
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: palettePrimary.main,
      },
    },
  },
};

export default components;
